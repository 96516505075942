import {
  CONTACT_SET_TEAM_MEMBERS_LIST,
  CONTACT_SET_SS_TEAM_LIST,
  CONTACT_SET_SS_TEAM_LIST_UPDATE,
  CORE_SET_ADD_CLIENT_FORM_VISIBILITY,
  CORE_SET_SS_CLIENT_LIST,
  CORE_SET_SS_CLIENT_LIST_UPDATE,
  CORE_SET_SP_CLIENT_LIST,
  CORE_SET_SP_CLIENT_LIST_UPDATE,
  CORE_SET_SS_MODAL_SAVE,
  CORE_SET_SS_OTHER_CLIENT_LIST,
  CORE_SET_SS_OTHER_CLIENT_LIST_UPDATE,
  CONTACT_TEMP_SS_LIST,
  CORE_SET_SS_CLIENT_LIST_DELETE,
  CORE_SET_SP_CLIENT_LIST_DELETE,
  CORE_SET_SS_OTHER_CLIENT_LIST_DELETE,
  CONTACT_SET_SS_TEAM_LIST_DELETE,
  RESET_SS_LIST,
  CONTACT_SS_CARDS,
  CONTACT_ACTIVE_SS_PLAN,
  CONTACT_ALL_SS_CARDS,
  CONTACT_SEARCH_SS_CARDS,
  CONTACT_SS_COMPANY,
  IS_OTHER_CONTACT,
  LOGO, THEME_COLOR
} from "./../types";
import { RESET_STORE } from "../types";
import apiRequest from "../../@core/axios";

// Initial state
export const initialState = {
  addClientFormVisibility: false,
  ssSaveandContinue: false,
  strategyPlans: {},
  strategyCards: {},
  strategyCompany: {},
  isOtherContact: false,
  logo: "",
  themeColor: "",
  activeStrategyPlan: {
    clients: [],
    created_at: "",
    id: null,
    improvement_program: {},
    product_options: [],
    province_id: null,
    purchase_costs: [],
    strategy_session_id: null,
    title: "",
    type: "",
    updated_at: ""
  },
  activeStrategyPlanLoading: false,
  setTeamMembersList: [],
  setFamilyMembersList: [],
  // ssClientList: Array<{ id: string; type: string }>(),
  ssClientList: [],
  spClientList: Array<{ id: string; type: string }>(),
  ssClientContactList: [],
  // ssOtherClientList: Array<{ id: string; type: string }>(),
  ssOtherClientList: [],

  ssTeamList: [],
  ssFamilyList: [],
  ssClient: {},
  tempSS: {
    brokers: [],
    clients: [],
    creator: {},
    creator_id: null,
    id: null,
    other_contacts: []
  },
  contactSearchResults: [],
};

export function setAddClientVisibility(addClientFormVisibility = true) {
  return {
    type: CORE_SET_ADD_CLIENT_FORM_VISIBILITY,
    payload: { addClientFormVisibility },
  };
}

export function setTempSSData(tempSS: any) {
  return {
    type: CONTACT_TEMP_SS_LIST,
    payload: { tempSS },
  };
}

export function addStrategyPlan(id: any, data: any): Promise<any> {
  return apiRequest({
    url: `strategy-sessions/${id}/strategy-plans`,
    method: "POST",
    data,
  });
}

export function copyProductOption(item_id: any, option_id: any): Promise<any> {
  return apiRequest({
    url: `product-option-items/${item_id}/copy/${option_id}`,
    method: 'POST'
  })
}

export function duplicateProductOption(id: any): Promise<any> {
  return apiRequest({
    url: `product-options/${id}/duplicate`,
    method: 'POST'
  })
}

export function updateStrategyPlanCard(id: any, data: any): Promise<any> {
  return apiRequest({
    url: `strategy-plans/${id}/update`,
    method: "POST",
    data,
  });
}

export function duplicateStrategyPlanCard(id: any): Promise<any> {
  return apiRequest({
    url: `strategy-plans/${id}/duplicate`,
    method: "POST",
  });
}

export function deleteStrategyPlanCard(id: any): Promise<any> {
  return apiRequest({
    url: `strategy-plans/${id}`,
    method: "DELETE",
  });
}

export function emptyPurchaseCostColumn(id: any): Promise<any> {
  return apiRequest({
    url: `strategy-plans/${id}/purchase-costs`,
    method: "POST",
  });
}

export function duplicatePurchaseCostColumn(id: any): Promise<any> {
  return apiRequest({
    url: `purchase-costs/${id}/duplicate`,
    method: "POST",
  });
}

export function deletePurchaseCostColumn(id: any): Promise<any> {
  return apiRequest({
    url: `purchase-costs/${id}`,
    method: "DELETE",
  });
}

export function updateMortgageRenewal(id: any, data: any): Promise<any> {
  return apiRequest({
    url: `mortgage-renewals/${id}/update`,
    method: "POST",
    data,
  });
}

export function updateSaleCost(id: any, data: any): Promise<any> {
  return apiRequest({
    url: `sale-costs/${id}/update`,
    method: "POST",
    data,
  });
}

export function selectPurchaseCost(id: any): Promise<any> {
  return apiRequest({
    url: `purchase-costs/${id}/select`,
    method: "POST",
  });
}

export function updatePurchaseCost(id: any, data: any): Promise<any> {
  return apiRequest({
    url: `purchase-costs/${id}/update`,
    method: "POST",
    data,
  });
}
export function updateOwnershipCost(id: any, data: any): Promise<any> {
  return apiRequest({
    url: `ownership-costs/${id}/update`,
    method: "POST",
    data,
  });
}

export function updateFutureEquity(id: any, data: any): Promise<any> {
  return apiRequest({
    url: `future-equities/${id}/update`,
    method: "POST",
    data,
  });
}

export function updateMonthlyRevenue(id: any, data: any): Promise<any> {
  return apiRequest({
    url: `monthly-revenues/${id}/update`,
    method: "POST",
    data,
  });
}



export function updateImprovementPrograms(id: any, data: any): Promise<any> {
  return apiRequest({
    url: `improvement-programs/${id}/update`,
    method: "POST",
    data,
  });
}

export function addStrategySession(data: any): Promise<any> {
  return apiRequest({
    url: "strategy-sessions",
    method: "POST",
    data,
  });
}
export function updateStrategySession(ssId: any, data: any): Promise<any> {
  return apiRequest({
    url: `strategy-sessions/${ssId}/contacts/update`,
    method: "POST",
    data,
  });
}

export function addClient(data: any): Promise<any> {
  return apiRequest({
    url: "contacts",
    method: "POST",
    data,
  });
}

export function getContactsOverview(): Promise<any> {
  return apiRequest({
    url: "contacts/overview",
    method: "GET",
  });
}

export function getSingleContactTable(id: any, data: any = {}): Promise<any> {
  return apiRequest({
    url: `contacts/${id}/strategies`,
    method: "GET",
    ...data,
  });
}

export function OtherCostDefaultOptions(): Promise<any> {
  return apiRequest({
    url: `defaults`,
    method: "GET",
  });
}

export function setSingleContactTableSearchResults(data: any) {
  return {
    type: CONTACT_SEARCH_SS_CARDS,
    payload: data,
  };
}
export function getStrategySessionCards(id: any) {
  return apiRequest({
    url: `strategy-sessions/${id}`,
    method: "GET",
  });
}

export function getStrategySessionClientsList(id: any) {
  return apiRequest({
    url: `strategy-sessions/${id}/clients`,
    method: "GET",
  });
}

export function getStrategySessionBrokersList(id: any) {
  return apiRequest({
    url: `strategy-sessions/${id}/brokers`,
    method: "GET",
  });
}

export function getStrategySessionContacts(id: any) {
  return apiRequest({
    url: `strategy-sessions/${id}/contacts`,
    method: "GET",
  });
}

export function getStrategySessionOthersList(id: any) {
  return apiRequest({
    url: `strategy-sessions/${id}/other-contacts`,
    method: "GET",
  });
}


export function setStrategySessionCompany(data: any) {
  return {
    type: CONTACT_SS_COMPANY,
    payload: data,
  };
}

export function setCurrentUserType(data: boolean) {
  return {
    type: IS_OTHER_CONTACT,
    payload: data,
  };
}

export function setCompanyLogo(data: string) {
  return {
    type: LOGO,
    payload: data,
  };
}

export function setStrategySessionCards(data: any) {
  return {
    type: CONTACT_SS_CARDS,
    payload: data,
  };
}

export function searchStrategyPlans(query: any, type: any = "") {
  return apiRequest({
    url: `strategy-plans?search=${query}&type=${type}`,
    method: "GET",
  });
}

export function searchClientStrategyPlans(query: any, type: any = "") {
  return apiRequest({
    url: `strategy-plans/access-level/client?search=${query}&type=${type}`,
    method: "GET",
  });
}


export function searchOtherContactStrategyPlans(query: any, type: any = "") {
  return apiRequest({
    url: `strategy-plans/access-level/other-contact?search=${query}&type=${type}`,
    method: "GET",
  });
}


export function searchContactStrategySession(id: any, query: any) {
  return apiRequest({
    url: `contacts/${id}/strategies?search=${query}`,
    method: "GET",
  });
}

export function getStrategyPlans(data: any) {
  return apiRequest({
    url: `strategy-plans/`,
    method: "GET",
    ...data
  });
}

export function getClientStrategyPlans(data: any, query: any = "", order: any = "", type: any = "") {
  return apiRequest({
    url: `strategy-plans/access-level/client?search=${query}&order=${order}&type=${type}`,
    method: "GET",
    ...data
  });
}

export function getOtherContactStrategyPlans(data: any, query: any = "", order: any = "", type: any = "") {
  return apiRequest({
    url: `strategy-plans/access-level/other-contact?search=${query}&order=${order}&type=${type}`,
    method: "GET",
    ...data
  });
}



export function getStrategyPlansFilter(type: any) {
  return apiRequest({
    url: `strategy-plans?type=${type}`,
    method: "GET",
  });
}

export function getClientStrategyPlansFilter(type: any) {
  return apiRequest({
    url: `strategy-plans/access-level/client?type=${type}`,
    method: "GET",
  });
}

export function getOtherContactStrategyPlansFilter(type: any) {
  return apiRequest({
    url: `strategy-plans/access-level/other-contact?type=${type}`,
    method: "GET",
  });
}

export function setAllStrategyPlans(data: any, cb: (() => any) | undefined) {
  return {
    type: CONTACT_ALL_SS_CARDS,
    payload: data,
    cb: cb && cb(),
  };
}

export function getSingleStrategyPlan(id: any, token: any) {
  return apiRequest({
    url: `strategy-plans/${id}`,
    method: "GET",
    cancelToken: token
  });
}

export function setActiveSSPlan(data: any, loading: boolean=false) {
  return {
    type: CONTACT_ACTIVE_SS_PLAN,
    payload: data,
    loading: loading,
  };
}

export function getClientList(query: any) {
  return apiRequest({
    url: `contacts?items=25&search=${query}`,
    method: "GET",
  });
}
export function getTeamMembersList() {
  return apiRequest({
    url: `company/members`,
    method: "GET",
  });
}

export function sendAccessSingle(ssID: any, contactID: any): Promise<any> {
  return apiRequest({
    url: `strategy-sessions/${ssID}/contacts/send-access/clients/${contactID}`,
    method: "POST",
  });
}

export function resendAccessSingle(ssID: any, contactID: any): Promise<any> {
  return apiRequest({
    url: `strategy-sessions/${ssID}/contacts/send-access/clients/${contactID}/resend`,
    method: "POST",
  });
}


export function sendAccessAll(ssID: any): Promise<any> {
  return apiRequest({
    url: `strategy-sessions/${ssID}/contacts/send-access/clients`,
    method: "POST",
  });
}

export function sendAccessOtherSingle(ssID: any, contactID: any): Promise<any> {
  return apiRequest({
    url: `strategy-sessions/${ssID}/contacts/send-access/other-contacts/${contactID}`,
    method: "POST",
  });
}

export function resendAccessOtherSingle(ssID: any, contactID: any): Promise<any> {
  return apiRequest({
    url: `strategy-sessions/${ssID}/contacts/send-access/other-contacts/${contactID}/resend`,
    method: "POST",
  });
}

export function sendAccessOtherAll(ssID: any): Promise<any> {
  return apiRequest({
    url: `strategy-sessions/${ssID}/contacts/send-access/other-contacts`,
    method: "POST",
  });
}


export function getAccessedContacts(ssID: any) {
  return apiRequest({
    url: `strategy-sessions/${ssID}/contacts`,
    method: "GET",
  });
}

export function getClientPlansType() {
  return apiRequest({
    url: `strategy-plans/enabled-types/access-level/client`,
    method: "GET",
  });
}

export function getOtherContactPlansType() {
  return apiRequest({
    url: `strategy-plans/enabled-types/access-level/other-contact`,
    method: "GET",
  });
}

export function purchaseAdditionalTeamMemberLicense() {
  return apiRequest({
    url: `/company/members/purchase`,
    method: "POST",
  });
}

export function getActiveMemberPlan() {
  return apiRequest({
    url: `/company/members/plan`,
    method: "GET",
  });
}

export function getCompanyInfo() {
  return apiRequest({
    url: `/company`,
    method: "GET",
  });
}

export function updateCompanyInfo(data: any) {
  return apiRequest({
    url: `/company`,
    method: "POST",
    data,
  });
}

export function getCompanyMembers() {
  return apiRequest({
    url: `/company/members`,
    method: "GET",
  });
}

export function getCompanySettings() {
  return apiRequest({
    url: `/company/settings`,
    method: "GET",
  });
}

export function updateCompanySettings(data: any) {
  return apiRequest({
    url: `/company/settings`,
    method: "POST",
    data
  });
}

export function getCompanyMemberDetails(id: any) {
  return apiRequest({
    url: `/company/members/${id}/details`,
    method: "GET",
  });
}

export function updateCompanyMemberDetails(id: any, data: any) {
  return apiRequest({
    url: `/company/members/${id}/details`,
    method: "POST",
    data
  });
}

export function setThemeColor(color: any) {
  return {
    type: THEME_COLOR,
    payload: color
  }

}

export function setTeamMembersList(data: any[]) {
  return {
    type: CONTACT_SET_TEAM_MEMBERS_LIST,
    payload: data,
  };
}

export function setSSTeamList(data: any[]) {
  return {
    type: CONTACT_SET_SS_TEAM_LIST,
    payload: data,
  };
}

export function updateSSTeamListItem(data: any[]) {
  return {
    type: CONTACT_SET_SS_TEAM_LIST_UPDATE,
    payload: data,
  };
}

export function deleteSSTeamListItem(data: any[]) {
  return {
    type: CONTACT_SET_SS_TEAM_LIST_DELETE,
    payload: data,
  };
}

export function setSSCLientList(data: any[]) {
  return {
    type: CORE_SET_SS_CLIENT_LIST,
    payload: data,
  };
}
export function updateSSCLientListItem(data: any[]) {
  return {
    type: CORE_SET_SS_CLIENT_LIST_UPDATE,
    payload: data,
  };
}
export function deleteSSCLientListItem(data: any[]) {
  return {
    type: CORE_SET_SS_CLIENT_LIST_DELETE,
    payload: data,
  };
}
export function setSPCLientList(data: any[]) {
  return {
    type: CORE_SET_SP_CLIENT_LIST,
    payload: data,
  };
}
export function updateSPCLientListItem(data: any) {
  return {
    type: CORE_SET_SP_CLIENT_LIST_UPDATE,
    payload: data,
  };
}
export function deleteSPCLientListItem(data: any[]) {
  return {
    type: CORE_SET_SP_CLIENT_LIST_DELETE,
    payload: data,
  };
}

export function setSSModalSave(ssSaveandContinue = true) {
  return {
    type: CORE_SET_SS_MODAL_SAVE,
    payload: { ssSaveandContinue },
  };
}

export function setSSOtherCLientList(data: any[]) {
  return {
    type: CORE_SET_SS_OTHER_CLIENT_LIST,
    payload: data,
  };
}
export function updateSSOtherCLientListItem(data: any[]) {
  return {
    type: CORE_SET_SS_OTHER_CLIENT_LIST_UPDATE,
    payload: data,
  };
}
export function deleteSSOtherCLientListItem(data: any[]) {
  return {
    type: CORE_SET_SS_OTHER_CLIENT_LIST_DELETE,
    payload: data,
  };
}
export function resetSSLists() {
  return {
    type: RESET_SS_LIST,
  };
}

export function ContactReducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case CORE_SET_SS_MODAL_SAVE:
      return {
        ...state,
        ssSaveandContinue: action.payload.ssSaveandContinue,
      };
    case CORE_SET_ADD_CLIENT_FORM_VISIBILITY:
      return {
        ...state,
        addClientFormVisibility: action.payload.addClientFormVisibility,
      };
    case CORE_SET_ADD_CLIENT_FORM_VISIBILITY:
      return {
        ...state,
        addClientFormVisibility: action.payload.addClientFormVisibility,
      };
    case CONTACT_TEMP_SS_LIST:
      return {
        ...state,
        tempSS: action.payload.tempSS,
      };
    case CONTACT_SS_CARDS:
      return {
        ...state,
        strategyCards: action.payload,
      };
    case CONTACT_SS_COMPANY:
      return {
        ...state,
        strategyCompany: action.payload,
      };

    case IS_OTHER_CONTACT:
      return {
        ...state,
        isOtherContact: action.payload,
      };

    case LOGO:
      return {
        ...state,
        logo: action.payload,
      };

    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.payload,
      };

    case CONTACT_ALL_SS_CARDS:
      return {
        ...state,
        strategyPlans: action.payload,
      };
    case CONTACT_ACTIVE_SS_PLAN:
      return {
        ...state,
        activeStrategyPlan: action.payload ? action.payload : state.activeStrategyPlan,
        activeStrategyPlanLoading: action.loading ? action.loading : false,
      };
    case CONTACT_SEARCH_SS_CARDS:
      return {
        ...state,
        contactSearchResults: action.payload,
      };

    case CONTACT_SET_TEAM_MEMBERS_LIST:
      return {
        ...state,
        setTeamMembersList: action.payload.setTeamMembersList,
      };
    case CORE_SET_SS_CLIENT_LIST:
      return {
        ...state,
        // ssClientList: [...state.ssClientList, ...action.payload],
        ssClientList: [...action.payload],
      };

    case CORE_SET_SS_CLIENT_LIST_UPDATE:
      const index = state.ssClientList.findIndex(
        (item: any) => item.id == action.payload.id
      );
      let newCopy = [...state.ssClientList];
      newCopy[index] = action.payload;
      return {
        ...state,
        ssClientList: newCopy,
      };

    case CORE_SET_SS_CLIENT_LIST_DELETE:
      return {
        ...state,
        ssClientList: state.ssClientList.filter(
          (item: any) => item.id !== action.payload.id
        ),
      };

    case CORE_SET_SP_CLIENT_LIST:
      if (state.hasOwnProperty('activeStrategyPlan') && state.activeStrategyPlan) {
        return {
          ...state,
          activeStrategyPlan: {
            ...state.activeStrategyPlan,
            clients: [...state?.activeStrategyPlan?.clients, ...action.payload]
          }
        };
      } else {
        return {
          ...state,
          activeStrategyPlan: {
            ...state.activeStrategyPlan,
            clients: [...action.payload]
          }
        };
      }

    case CORE_SET_SP_CLIENT_LIST_UPDATE:
      // console.log(`plasn: ${Object.keys(state.activeStrategyPlan)}`)
      let sp_index
      let spNewCopy = []
      if (state.hasOwnProperty('activeStrategyPlan') && state.activeStrategyPlan) {
        sp_index = state.activeStrategyPlan?.clients?.findIndex(
          (item: any) => item.id == action.payload.id
        );
        spNewCopy = [...state.activeStrategyPlan.clients];
      } else if (state.ssClientList.length > 0) {
        sp_index = state.ssClientList.findIndex(
          (item: any) => item.id == action.payload.id
        );
        spNewCopy = [...state.ssClientList];
      } else {
        sp_index = 0
      }
      spNewCopy[sp_index] = action.payload;
      return {
        ...state,
        activeStrategyPlan: {
          ...state.activeStrategyPlan,
          clients: spNewCopy
        }
      };

    case CORE_SET_SP_CLIENT_LIST_DELETE:
      return {
        ...state,
        activeStrategyPlan: {
          ...state.activeStrategyPlan,
          clients: state.activeStrategyPlan.clients.filter(
            (item: any) => item.id !== action.payload.id
          ),
        }
      };
    case CORE_SET_SS_OTHER_CLIENT_LIST:
      return {
        ...state,
        // ssOtherClientList: [...state.ssOtherClientList, ...action.payload],
        ssOtherClientList: [...action.payload],
      };
    case CORE_SET_SS_OTHER_CLIENT_LIST_UPDATE:
      const i = state.ssOtherClientList.findIndex(
        (item: any) => item.id == action.payload.id
      );
      let nCopy = [...state.ssOtherClientList];
      nCopy[i] = action.payload;
      return {
        ...state,
        ssOtherClientList: nCopy,
      };
    case CORE_SET_SS_OTHER_CLIENT_LIST_DELETE:
      return {
        ...state,
        ssOtherClientList: state.ssOtherClientList.filter(
          (item: any) => item.id !== action.payload.id
        ),
      };

    case CONTACT_SET_SS_TEAM_LIST:
      return {
        ...state,
        // ssTeamList: [...state.ssTeamList, ...action.payload],
        ssTeamList: [...action.payload],
      };
    case CONTACT_SET_SS_TEAM_LIST_UPDATE:
      const x = state.ssClientList.findIndex(
        (item: any) => item.id == action.payload.id
      );
      let copy = [...state.ssClientList];
      copy[x] = action.payload;
      return {
        ...state,
        ssTeamList: copy,
      };
    case CONTACT_SET_SS_TEAM_LIST_DELETE:
      return {
        ...state,
        ssTeamList: state.ssTeamList.filter(
          (item: any) => item.id !== action.payload.id
        ),
      };
    case RESET_SS_LIST:
      return {
        ...state,
        ssClientList: [],
        ssTeamList: [],
        ssOtherClientList: [],
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
